import React from 'react'

import SideNav from './SideNav'
import SideSubNav from './SideSubNav'

import './Side.scss'

function Side(props) {
  return (
    <aside className="Side">
      <SideNav />
      <SideSubNav />
    </aside>
  )
}

export default Side;