import React from 'react'

import { ReactComponent as Logo } from '../../../assets/ui/logo.svg'
import classNames from 'classnames'

// COMPONENTS
import Icon from '../../atoms/Icon/Icon'
import Avatar from '../../molocules/Avatar/Avatar'

// FUNCTIONS
function SideNav(props) {
  return (
    <nav className="SideNav">
      <div className="SideNavMenu">
        <SideNavItem icon="clients-medium" name="Clients" active to="/" />
        <SideNavItem icon="practice-medium" name="Practice" to="/" />
        <SideNavItem icon="settings-medium" name="Settings" to="/" />
        <SideNavItem icon="initials" name="Profile" to="/" />
        <SideNavItem icon="help-medium" name="Help" to="/" />
      </div>
      <div className="SideNavOther">
        <a href="/" className="Logo">
          <Logo />
        </a>
      </div>
    </nav>
  )
}

function SideNavItem({name, icon, to, active, ...props}) {
  const classes = classNames({
    "SideNavItem": true,
    "is-active": active
  })

  let itemContent = "";

  if (icon === "initials") {
    itemContent = <Avatar shape={false} initials="JRr" />
  } else {
    itemContent = <Icon name={icon} size="medium" />
  }

  return (
    <a href={to} className={classes}>
      <div className="SideNavItem-content">
        { itemContent }
      </div>
      <span className="label">{name}</span>
    </a>
  )
}

export default SideNav;