import React, { useState } from 'react'
import CardHeader from './CardHeader'
import CardContent from './CardContent'
import CardFooter from './CardFooter'
import Button from '../../atoms/Button/Button'

// CSS
import './Card.scss';


const Card = props => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  }

  return (
    <div className="Card">
      {props.title &&
        <CardHeader
          title={props.title}
          subtitle={props.subtitle}
          isEditing={isEditing}
          edit={props.edit}
          handler={handleEdit}
        />
      }

      <CardContent>
        {props.children}
      </CardContent>

      {isEditing &&
        <CardFooter>
          <Button onClick={handleEdit} className="test">Cancel</Button>
          <Button primary onClick={handleEdit}>Save changes</Button>
        </CardFooter>
      }
    </div>
  )
}


export default Card;