import React from 'react'
import classNames from 'classnames'

// COMPONENTS
import Icon from '../../atoms/Icon/Icon'

// CSS
import './Button.scss'

const Btn = ({as, children, ...props }) => {
  return React.createElement(`${as}`, props, children)
}

function Button({secondary, external, primary, children, loading, link, icon, ...props}) {

  const classes = classNames({
    "Button": true,
    "is-secondary": secondary,
    "is-primary": primary,
    "is-link": link,
    "is-loading": loading,
  })
  
  const target = external ? "_blank" : ""
  const rel = external ? "noopener noreferrer" : ""
  
  return (
    <Btn
      title={props.title} {...props}
      className={classes}
      target={target}
      rel={rel}
    >
      {
        icon &&
        <span className="icon">
          <Icon name={icon} />
        </span>
      }
      {
        children &&
        <span className="text">
          {children}
        </span>
      }
    </Btn>
  )
}

Btn.defaultProps = {
  as: "button",
}

Button.defaultProps = {
  primary: false,
  secondary: true,
  external: false,
  link: false,
  loading: false,
}

export default Button;