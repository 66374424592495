import React from 'react'

// COMPONENTS
import MainHeader from "../organisms/Main/MainHeader"
import Card from "../molocules/Card/Card"
import CardSection from '../molocules/Card/CardSection';
import Title from "../atoms/Title/Title"
import Button from "../atoms/Button/Button"
import { Row, Column } from "../atoms/Columns/Columns"

function Why(props) {
  return (
    <div className="container">
      <MainHeader title="Front-end Designer" primaryButton="Create role" />
      <Row>
        <Column width="8">
          <Card title="What is a front-end designer?" edit="true">
            <CardSection>
              <p>Traditionally, a front-end developer is someone that writes code that the browser has to translate, like html, css and javascript.</p>

              <Title size="4">A front-end designer does the following</Title>

              <Row>
                <Column>Implements code according to the visual designs made by the visual designer and product design team, in the best way possible.</Column>
                <Column>Maintaines html/css so it doesn't break in any browsers, and is as optimized as possible.</Column>
              </Row>
              <Row>
                <Column>Makes sure the code is accesible for screenreaders and people that can't see properly.</Column>
                <Column>Creates a base structure for the the whole application that is easy to be picked up by developers, without touching any CSS.</Column>
              </Row>
            </CardSection>
          </Card>
          <Card
            title="Importance of having a consistent product"
            subtitle="There are 3 key points in a product that are all important."
          >
            <CardSection>
              <Title size="4">Consistency in looks</Title>
              <ul>
                <li>People like beauty. From an evolution perspective, beauty means high quality food, tools, partners. It's something we all enjoy. But, it's subcontious.</li>
                <li>A product can still work without looking good, but it doesn't feel as good</li>
                <li>Building a good product is a skill, and people appreciate it when a product is well built, in every aspect.</li>
              </ul>
            </CardSection>
            <CardSection>
              <Title size="4">Consistency in patterns</Title>
              <ul>
                <li>Every pattern should work the same across the product. You're setting an expectation for the user that if they click on something, the expected thing will happen. </li>
                <li>If teams build all the patterns seperately, the user will very easily be confused.</li>
              </ul>
            </CardSection>
            <CardSection>
              <Title size="4">Consistency in behaviour</Title>
              <p>Animations, hover effects, clicking effects, should all be the same across product.</p>
            </CardSection>
          </Card>
          <Card title="Why do you need front-end designer/developers?">
            <CardSection>
              <Title size="4">Fill the gap</Title>
              <p>Currently at MYOB, there's a big gap between designers and developers. MYOB mostly has "full stack" developers. With the idea that they can do any kind of programming. But, a full stack developer doesn't really exist.</p>
            </CardSection>
            <CardSection>
              <Title size="4">Front-end development is a skill</Title>
              <p>Visual design is a skill, product design is a skill, front-end development is a skill, and back-end programming is a skill. They're all different and require a different kind of thinking.</p>
            </CardSection>
            <CardSection>
              <Title size="4">Engineers are not front-end developer</Title>
              <p>We have a lot of developers that also implement html/css, but there's nobody that really focusses on the experience for the users. We have the particle team that creates components and assists teams, but that's not enough to create a user friendly experience across the product.</p>
            </CardSection>
          </Card>
          <Card title="Symptoms of a broken system">
            <CardSection>
              <Title size="4">The product is inconsistent</Title>
              <ol>
                <li>This is because every team has their own deadlines, and will implement code in a way that completes their goal.</li>
                <li>There is no set system and rules to follow apart from components</li>
                <li>The importance of good html/css is not understood or given enough priority</li>
              </ol>
            </CardSection>
            <CardSection>
              <Title size="4">Teams code in silo's</Title>
              <p>Without a good system, rules and best practices, teams will implement features without making sure it's good quality and checking with front-end developers to see if it follows the right practies.</p>
            </CardSection>
            <CardSection>
              <Title size="4">Small changes take forever to get implemented</Title>
              <ol>
                <li>Cards, grey backgrounds, correct title colors and size, do I need to say more?</li>
                <li>This is because every team has to manually implement a change.</li>
                <li>What happens next year, when we tweak the design a little bit? All teams will need to change it again. This costs an insane amount of manpower time that could be spent otherwise.</li>
              </ol>
            </CardSection>
          </Card>
          <Card title="If we had one, what would he/she do?">
            <CardSection>
              <ol>
                <li>Look at the current code base and suggest changes we can make to make everything consistent and scalable for the future</li>
                <li>Build a base structure that other teams can work with</li>
                <li>Educate teams individually
                          <ol>
                    <li>Which components to use</li>
                    <li>The importance of following a system</li>
                    <li>How to set up their page structure</li>
                    <li>Who to talk to with questions</li>
                    <li>Find one person per team that can be an advocate and hold team accountable for good implimentation</li>
                    <li>Help teams code features before they build the logic</li>
                    <li>How to follow the components and styles established in the MYOB design system - Feelix</li>
                  </ol>
                </li>
                <li>Communicate with feelix about improving components and implementing</li>
                <li>Upskill developers that are interested in Front-end</li>
                <li>Upskill developers that are interested in Front-end</li>
              </ol>
            </CardSection>
          </Card>
          <Card title="How does it benefit MYOB in the long term?">
            <CardSection>
              <ol>
                <li>MYOB can have an application that is consistent, feels fast, and is easy to use for the user.</li>
                <li>You save an insane amount of man hours, and frustration</li>
                <li>You're ready for changes across the product in the future</li>
              </ol>
            </CardSection>
          </Card>
        </Column>
        <Column width="4">
          <Card>
            <CardSection>
              <p>I think we need a front-end designer. Here's why.</p>
            </CardSection>
          </Card>
          <Card title="About me">
            <CardSection>
              <p>I am a visual designer and front-end developer by trade.</p>
              <div className="buttons is-centered">
                <Button external as="a" href="https://www.notion.so/jonnotie/MYOB-Resume-9146383aa19442b2a5331fed140d0c65">My resume</Button>
                <Button external as="a" href="https://jonno.netlify.com/">My portfolio</Button>
              </div>
            </CardSection>
          </Card>
          <Card title="Useful links">
            <CardSection>
              <p>Check out these articles and videos that touch on the subject of front-end design/development.</p>
              <ul>
                <li>
                  <a href="https://css-tricks.com/front-end-development-is-not-a-problem-to-be-solved/" target="_blank" rel="noopener noreferrer">Front-end development is not a problem to be solved </a>
                </li>
                <li>
                  <a href="https://vimeo.com/282452432" target="_blank" rel="noopener noreferrer">Motion & Playfulness</a>
                </li>
                <li>
                  <a href="https://medium.com/@mandy.michael/is-there-any-value-in-people-who-cannot-write-javascript-d0a66b16de06" target="_blank" rel="noopener noreferrer">Is there any value in people who cannot write JavaScript?</a>
                </li>
                <li>
                  <a href="https://www.youtube.com/watch?v=grSxHfGoaeg" target="_blank" rel="noopener noreferrer">The All Powerful Front End Developer</a>
                </li>
              </ul>
            </CardSection>
          </Card>
          <Card title="Xero did it…">
            <CardSection>
              <p>When I worked at Xero, they had a really well known <a href="https://snook.ca/about/" target="_blank" rel="noopener noreferrer">front-end developer</a> build a system for them which helped with the consistency they have today.</p>
            </CardSection>
          </Card>
        </Column>
      </Row>
    </div>
  )
}

export default Why;