import React from 'react'
import Title from '../../atoms/Title/Title'
import Button from '../../atoms/Button/Button'

const CardHeader = props => {
  return (
    <header className="CardHeader">
      <div className="level">
        <div className="level-left">
          <Title size="3">{props.title}</Title>
        </div>
        {
          props.edit && !props.isEditing && 
          <div className="level-right">
            <div className="buttons">
              <Button link="true" icon="edit" onClick={props.handler}>Edit details</Button>
            </div>
          </div>
        }
      </div>
    </header>
  )
};


export default CardHeader;