import React from 'react'
import classNames from 'classnames'

// CSS
import "./Columns.scss";


// ROWS
const Row = props => {
  const classes = classNames({
    "Columns": true,
    "is-centered": props.secondary,
    "is-multiline": props.stacked,
    "is-mobile": props.mobile,
  })

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
};

// COLUMN

const Column = props => {
  const classes =
    `${props.width ? `is-${props.width} ` : ``}` +
    `${props.mobile ? `is-${props.mobile}-mobile ` : ``}` +
    `${props.tablet ? `is-${props.tablet}-tablet ` : ``}` +
    `${props.desktop ? `is-${props.desktop}-desktop ` : ``}` +
    `${props.fullhd ? `is-${props.fullhd}-fullhd ` : ``}`
  ;

  return (
    <div className={`Column ${classes}`}>
      {props.children}
    </div>
  )
};

Column.defaultProps = {
}

export {Row, Column};