import React from 'react'
import classNames from 'classnames'

// CSS
import './Avatar.scss'

function Avatar({active, business, initials, shape, ...props}) {
  const classes = classNames({
    "Avatar": true,
    "is-business": business,
    "is-active": active,
    "has-noshape": shape === false,
  })

  const shortInitials = initials.substring(0, 2)

  return (
    <div className={classes} {...props}>
      <span className="Avatar-initials">{shortInitials}</span>
    </div>
  )
}

Avatar.defaultProps = {
  active: false,
  business: false,
}

export default Avatar;