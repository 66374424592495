import React from 'react'

function CardSection(props) {
  return (
    <section className="CardSection">
      {props.children}
    </section>
  )
}

export default CardSection;