import React from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


// CSS
import './Main.scss'

// COMPONENTS
import MainNavigation from './MainNavigation';

// PAGE
import WhyPage from '../../pages/Why'
import ChipsPage  from '../../pages/Chips'


function Content(props) {
  return (
    <Router>
      <main className="Main">
        <MainNavigation />

        <div className="MainScroll">
          <div className="MainContent">
            <Route exact path="/" component={WhyPage} />
            <Route exact path="/practice" component={ChipsPage} />
            
          </div>
        </div>
      </main>
    </Router>
  )
}

export default Content;