import React, { useState, useEffect } from 'react'
import { useLocalStorage } from 'react-use';

// ASSETS
import { ReactComponent as ToggleShape } from '../../../assets/ui/sidebar_toggle_shape.svg'

// COMPONENTS
import Icon from '../../atoms/Icon/Icon'


const bodyClass = (action, className) => {
  if (action === "add") {
    document.body.classList.add(className)
  } else if (action === "remove") {
    document.body.classList.remove(className)
  }
}

const SideSubNav = () => {
  // Save Sidebar state in local storage
  const [sidebarIsOpen, setSidebarIsOpen] = useLocalStorage('sidebarIsOpen', false);

  // Sidebar state
  const [isOpen, setIsOpen] = useState(sidebarIsOpen);

  // sidebar class
  const openClassName = "sidebar-is-open"

  useEffect(() => {
    !isOpen ? bodyClass("remove", openClassName) : bodyClass("add", openClassName)
  }, [isOpen])

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setSidebarIsOpen(!isOpen);
  }

  return (
    <div className="SideSubNav">
      <div className="container">
        {/* {isOpen ? "Open" : "Closed"} */}
      </div>
      <div className="toggle">
        <Icon name="chevron-left" className="toggle-arrow" />
        <ToggleShape className="toggle-shape" onClick={handleToggle} />
      </div>
    </div>
  )
}

export default SideSubNav;