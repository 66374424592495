import React from 'react'

function CardFooter(props) {
  return (
    <footer className="CardFooter">
      <div className="buttons">
        {props.children}
      </div>
    </footer>
  )
}

export default CardFooter;