import React from 'react'
import Avatar from '../../molocules/Avatar/Avatar'

function MainNavigation(props) {
  return (
    <div className="MainNavigation">
      <div className="MainPageNavigation">
        <div className="container">
          <div className="NavBar">
            <nav className="NavBar-left">
              <a href="/" className="NavBar-item is-active">
                <Avatar active business initials="JRr" />
                Jonno Riekwel
              </a>
              <a href="/" className="NavBar-item">Transaction processing</a>
              <a href="/" className="NavBar-item">Tasks</a>
              <a href="/" className="NavBar-item">Documents</a>
              <a href="/" className="NavBar-item">Compliance</a>
            
            </nav>
            <div className="NavBar-right">
              <div className="NavBar-clientcode">frntnddv</div>
            </div>
          </div>
        </div>
      </div>
      <div className="MainSubNavigation">
        <div className="container">
          <nav className="SubNavBar">
            <a href="/" className="MainSubNavigation-item is-active">Client list</a>
            <a href="/" className="MainSubNavigation-item">Portal list</a>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default MainNavigation;


