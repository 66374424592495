import React from 'react';

// CSS
import "./styles/base.scss"

// COMPONENTS
import Main from './components/organisms/Main/Main'
import Side from './components/organisms/Side/Side'


function App() {
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Side />
          <Main />
        </div>
      </div>
    </>
  );
}

export default App;
