import React from 'react'

function CardContent(props) {
  return (
    <main className="CardContent">
      {props.children}
    </main>
  )
}

export default CardContent;