import React from 'react'
import Button from "../../atoms/Button/Button"
import Title from "../../atoms/Title/Title"

function MainHeader(props) {
  return (
    <div className="MainHeader">
      <div className="level">
        <div className="level-left">
          <Title size="1">{props.title}</Title>
        </div>
        <div className="level-right">
          <Button primary icon={props.primaryIcon}>{props.primaryButton}</Button>
        </div>
      </div>
    </div>
  )
}

MainHeader.defaultProps = {
  title: "Page title",
  primaryIcon: "add",
  primaryButton: "Primary action",
}

export default MainHeader;


