import React from 'react'
import classNames from 'classnames'
import icons from './icons.svg'

// CSS
import './Icon.scss'

function Icon({name, size, className, ...props}) {
  const classes = classNames({
    "is-medium": size === "medium",
    "is-big": size === "big",
    "is-large": size === "large",
  })

  return (
    <svg className={`Icon ${classes} ${className}`}>
      <use xlinkHref={icons + `#icon-${name}`} />
    </svg>
  )
}

Icon.defaultProps = {
  className: ""
}

export default Icon;