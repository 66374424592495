import React from 'react'

// COMPONENTS
import MainHeader from "../organisms/Main/MainHeader"
import Card from "../molocules/Card/Card"
import CardSection from '../molocules/Card/CardSection';
import Title from "../atoms/Title/Title"
import Button from "../atoms/Button/Button"
import { Row, Column } from "../atoms/Columns/Columns"

function Chips(props) {
  return (
    <div className="container">
      <MainHeader title="Front-end Designer" primaryButton="Create role" />
      <Row>
        <Column width="8">
          <Card title="What is a front-end designer?" edit="true">
            <CardSection>
              <p>Traditionally, a front-end developer is someone that writes code that the browser has to translate, like html, css and javascript.</p>

              <Title size="4">A front-end designer does the following</Title>

              <Row>
                <Column>Implements code according to the visual designs made by the visual designer and product design team, in the best way possible.</Column>
                <Column>Maintaines html/css so it doesn't break in any browsers, and is as optimized as possible.</Column>
              </Row>
              <Row>
                <Column>Makes sure the code is accesible for screenreaders and people that can't see properly.</Column>
                <Column>Creates a base structure for the the whole application that is easy to be picked up by developers, without touching any CSS.</Column>
              </Row>
            </CardSection>
          </Card>
        </Column>
      </Row>
    </div>
  )
}

export default Chips;