import React from 'react'

// CSS
import './Title.scss'

const Heading = ({ size, children, ...props }) => {
  return React.createElement(`h${size}`, props, children)
}

function Title(props) {
  return (
    <Heading size={props.size} className={`Title is-${props.size}`}>{props.children}</Heading>
  )
}

Title.defaultProps = {
  size: 4,
};

export default Title;